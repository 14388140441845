import React from 'react';

const Ring = ({ color ,className}) => (
    <svg width="24" height="24" viewBox="0 0 24 24" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2400_130)">
            <path d="M22.6929 13.6583L20.7929 6.82227C20.236 4.81961 19.0257 3.06056 17.3543 1.82474C15.6829 0.588925 13.6463 -0.0527411 11.5684 0.00173899C9.49044 0.0562191 7.49033 0.803719 5.88599 2.12542C4.28164 3.44712 3.16513 5.26717 2.71392 7.29627L1.24292 13.9113C1.08044 14.6422 1.08419 15.4003 1.25391 16.1296C1.42363 16.8589 1.75499 17.5407 2.2235 18.1248C2.69202 18.7089 3.28574 19.1803 3.96083 19.5042C4.63592 19.8281 5.37514 19.9963 6.12392 19.9963H7.23792C7.46744 21.1266 8.08065 22.1427 8.97366 22.8727C9.86668 23.6026 10.9846 24.0013 12.1379 24.0013C13.2913 24.0013 14.4092 23.6026 15.3022 22.8727C16.1952 22.1427 16.8084 21.1266 17.0379 19.9963H17.8759C18.6467 19.9963 19.4071 19.8182 20.0977 19.4757C20.7882 19.1333 21.3903 18.6358 21.8567 18.0221C22.3232 17.4085 22.6415 16.6953 22.7867 15.9383C22.932 15.1813 22.8992 14.401 22.6929 13.6583ZM12.1379 21.9963C11.5197 21.9937 10.9173 21.8002 10.4132 21.4422C9.90915 21.0842 9.528 20.5792 9.32192 19.9963H14.9539C14.7478 20.5792 14.3667 21.0842 13.8626 21.4422C13.3586 21.8002 12.7562 21.9937 12.1379 21.9963ZM20.2639 16.8113C19.9853 17.1809 19.6242 17.4805 19.2095 17.6862C18.7948 17.8919 18.3378 17.9981 17.8749 17.9963H6.12392C5.67471 17.9962 5.23124 17.8952 4.82626 17.7009C4.42128 17.5065 4.06513 17.2236 3.78408 16.8732C3.50304 16.5227 3.30428 16.1136 3.20248 15.6761C3.10068 15.2386 3.09844 14.7838 3.19592 14.3453L4.66592 7.72927C5.02027 6.13548 5.89723 4.70588 7.15738 3.66772C8.41753 2.62957 9.98855 2.04244 11.6207 1.99969C13.2528 1.95693 14.8525 2.46099 16.1652 3.43174C17.478 4.40249 18.4286 5.78422 18.8659 7.35727L20.7659 14.1933C20.8915 14.6386 20.9115 15.1072 20.8243 15.5616C20.7372 16.0161 20.5453 16.4439 20.2639 16.8113Z" fill="black"/>
        </g>
        <defs>
            <clipPath id="clip0_2400_130">
                <rect width="23.7525" height="24.0028" fill="white" transform="translate(0.123779 -0.00146484)"/>
            </clipPath>
        </defs>
    </svg>




);

export default Ring;