import React, {createContext, useEffect, useState} from 'react';
import Sidebar from './sidebar';
import MobileSidebar from "./mobile_sidebar";
import Navbar from "./navbar";

export const VerticalLayoutContext = createContext();

const VerticalLayout = ({ children }) => {

    function formatDateToYYYYMMDD(date) {

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const [formData, setFormData] = useState(() => {
        const savedFormData = localStorage.getItem('formData');
        return savedFormData ? {
            selectedOption: { value: '1', label: 'Date Range' },
            from: JSON.parse(savedFormData).from === '' ? formatDateToYYYYMMDD(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 30))) : JSON.parse(savedFormData).from,
            to: JSON.parse(savedFormData).to === '' ? formatDateToYYYYMMDD(new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))) : JSON.parse(savedFormData).to,
        } : {
            selectedOption: { value: '1', label: 'Date Range' },
            from: formatDateToYYYYMMDD(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 30))),
            to: formatDateToYYYYMMDD(new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))),
        };
    });
    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]);

    return (
        <VerticalLayoutContext.Provider value={{ formData, setFormData}}>
                <div className="d-flex app-container">
                    <Sidebar />
                    <MobileSidebar formData={formData} setFormData={setFormData} />
                    <div className="main-content">
                        <Navbar formData={formData} setFormData={setFormData}/>
                        {children}
                    </div>
                </div>
        </VerticalLayoutContext.Provider>
    );
};

export default VerticalLayout;