import React from 'react';

const RectangleDotsSvgIcon = ({ color ,className}) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2400_121)">
            <path d="M1 8C0.734784 8 0.48043 7.89464 0.292893 7.70711C0.105357 7.51957 0 7.26522 0 7V5C0.00158786 3.67441 0.528882 2.40356 1.46622 1.46622C2.40356 0.528882 3.67441 0.00158786 5 0L7 0C7.26522 0 7.51957 0.105357 7.70711 0.292893C7.89464 0.48043 8 0.734784 8 1C8 1.26522 7.89464 1.51957 7.70711 1.70711C7.51957 1.89464 7.26522 2 7 2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V7C2 7.26522 1.89464 7.51957 1.70711 7.70711C1.51957 7.89464 1.26522 8 1 8Z" fill="black"/>
            <path d="M23 8C22.7348 8 22.4804 7.89464 22.2929 7.70711C22.1054 7.51957 22 7.26522 22 7V5C22 4.20435 21.6839 3.44129 21.1213 2.87868C20.5587 2.31607 19.7956 2 19 2H17C16.7348 2 16.4804 1.89464 16.2929 1.70711C16.1054 1.51957 16 1.26522 16 1C16 0.734784 16.1054 0.48043 16.2929 0.292893C16.4804 0.105357 16.7348 0 17 0L19 0C20.3256 0.00158786 21.5964 0.528882 22.5338 1.46622C23.4711 2.40356 23.9984 3.67441 24 5V7C24 7.26522 23.8946 7.51957 23.7071 7.70711C23.5196 7.89464 23.2652 8 23 8Z" fill="#1A1C1E"/>
            <path d="M7 24H5C3.67441 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5964 0.00158786 20.3256 0 19L0 17C0 16.7348 0.105357 16.4804 0.292893 16.2929C0.48043 16.1054 0.734784 16 1 16C1.26522 16 1.51957 16.1054 1.70711 16.2929C1.89464 16.4804 2 16.7348 2 17V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H7C7.26522 22 7.51957 22.1054 7.70711 22.2929C7.89464 22.4804 8 22.7348 8 23C8 23.2652 7.89464 23.5196 7.70711 23.7071C7.51957 23.8946 7.26522 24 7 24Z" fill="#1A1C1E"/>
            <path d="M19 24H17C16.7348 24 16.4804 23.8946 16.2929 23.7071C16.1054 23.5196 16 23.2652 16 23C16 22.7348 16.1054 22.4804 16.2929 22.2929C16.4804 22.1054 16.7348 22 17 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V17C22 16.7348 22.1054 16.4804 22.2929 16.2929C22.4804 16.1054 22.7348 16 23 16C23.2652 16 23.5196 16.1054 23.7071 16.2929C23.8946 16.4804 24 16.7348 24 17V19C23.9984 20.3256 23.4711 21.5964 22.5338 22.5338C21.5964 23.4711 20.3256 23.9984 19 24Z" fill="#1A1C1E"/>
        </g>
        <defs>
            <clipPath id="clip0_2400_121">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>


);

export default RectangleDotsSvgIcon;