


const NotFound = () =>{
    return(
        <h1>
            Not Found
        </h1>
    )

}

export default NotFound;