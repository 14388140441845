import React from 'react';



const AuthRoutes = [
    {
        navLink: '/login',
        component: React.lazy(() => import('../pages/login/login')),
        authRequired: false
    },
    {
        navLink: '/register',
        component: React.lazy(() => import('../pages/register/register')),
        authRequired: false
    },
    {
        navLink: '/create_account',
        component: React.lazy(() => import('../pages/create_account/create_account')),
        authRequired: false
    },
    {
        navLink: '/forget_password',
        component: React.lazy(() => import('../pages/forget_password/forget_password')),
        authRequired: false
    },
    {
        navLink: '/change_password',
        component: React.lazy(() => import('../pages/forget_password/change_password')),
        authRequired: false
    },
    {
        navLink: '/toc',
        component: React.lazy(() => import('../pages/toc/toc')),
        authRequired: false,
        layout:'vertical'
    },
];

export default AuthRoutes;