import React from 'react';
import { IntlProvider, createIntl, createIntlCache } from 'react-intl';
import {useLocale} from "../context/locale_context";


const cache = createIntlCache();

const CustomIntlProvider = ({ children, messages }) => {
    const { locale } = useLocale();

    const intl = createIntl(
        {
            locale,
            messages: messages[locale] || {},
            onError: (err) => {
                if (err.code === 'MISSING_TRANSLATION') {
                    console.warn(`Missing translation for: ${err.message}`);
                } else {
                    throw err;
                }
            },
        },
        cache
    );

    return (
        <IntlProvider locale={locale} messages={messages[locale]} onError={intl.onError}>
            {children}
        </IntlProvider>
    );
};

export default CustomIntlProvider;
