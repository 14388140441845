import HomeSvgIcon from "../components/icons/homeIcon";
import React from "react";
import RectangleSvgIcon from "@components/icons/rectangel";


const HomeNavItems = [
    {
        id: 'home',
        title: 'Home',
        icon: <RectangleSvgIcon className="sidebar-icon mr-2" />,
        link: '/home'
    },
]

export default HomeNavItems
