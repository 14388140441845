import React, {Suspense} from 'react';
import {Routes, Route} from 'react-router-dom';

import {Navigate} from "react-router";
import NotFound from "../pages/not_found";
import AuthRoutes from "@modules/auth/routes/auth_routes";
import HomeRoutes from "@modules/home/routes/home_routes";
import VerticalLayout from "../pages/layout/vertical_layout";




const AppRoutes = () => {
    const layout = 'vertical';

    const renderLayout = (layout, element) => {
        if (layout === 'vertical') {
            return <VerticalLayout>{element}</VerticalLayout>;
        }
        // Default to vertical layout if layout value is not recognized
        return <VerticalLayout>{element}</VerticalLayout>;
    };
    const isAuthenticated = localStorage.getItem('user') != null;

    const routes = [
        ...AuthRoutes,
        ...HomeRoutes
    ]
    const unAuthorizedRoutes = [
        ...AuthRoutes
    ]
if (true)
    return (
        <Routes>
            {
                routes.map((route, index) => {
                    return (

                        <Route key={index} path={route.navLink} element={route.layout ? renderLayout(route.layout_file,<Suspense fallback={<></>}>
                            <route.component/>
                        </Suspense>)  : <Suspense fallback={<></>}>
                            <route.component/>
                        </Suspense>}/>

                    )
                })
            }

            <Route path="*" element={<NotFound/>}/>
            <Route path="/404" element={<NotFound/>}/>
        </Routes>
    );
else
    return (
        <Routes>
            {
                unAuthorizedRoutes.map((route, index) => {
                    return (

                        <Route key={index} path={route.navLink} element={route.layout ? renderLayout(layout,<Suspense fallback={<route.component/>}>
                            <route.component/>
                        </Suspense>)  : <Suspense fallback={<></>}>
                            <route.component/>
                        </Suspense>}/>

                    )
                })
            }
            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    );
};

export default AppRoutes;