// locales/en.js
import authEn from '../modules/auth/locales/en.json';
import campaignEn from '../modules/campaign/locales/en.json';

const en = {
    ...authEn,
    ...campaignEn,
};

export default en;
