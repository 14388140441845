import React, { Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import '@assets/css/shared.scss';
import '@assets/js/sidebars.js';
import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { history } from './helpers/history';
import AppRoutes from "./routes/app_routes";
import { ReactFlowProvider } from 'reactflow';

import en from './locales/en';
import de from './locales/de';
import CustomIntlProvider from "./providers/custom_intl_provider";
import {LocaleProvider} from "./context/locale_context";


export { App };

function App() {
    history.navigate = useNavigate();
    history.location = useLocation();

    const [messages, setMessages] = useState({ en, de });

    return (
        <React.StrictMode>
            <LocaleProvider>
                <ReactFlowProvider>
                    <PrimeReactProvider>
                        <CustomIntlProvider messages={messages}>
                            <div className="app-container bg-white">
                                <Suspense fallback={<></>}>
                                    <AppRoutes />
                                </Suspense>
                            </div>
                        </CustomIntlProvider>
                    </PrimeReactProvider>
                </ReactFlowProvider>
            </LocaleProvider>
        </React.StrictMode>
    );
}
