import React, { useState} from 'react';
import {Sidebar} from "primereact/sidebar";
import {Button} from "primereact/button";
import logo from "../../assets/images/logo.png";


const MobileSidebar = ({ formData, setFormData }) => {
    const [visible, setVisible] = useState(false);

return (
    <div className={'mobile-sidebar d-md-none'}>
        <Sidebar visible={visible} onHide={() => setVisible(false)}>
            <a href="/" className="align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                <img src={logo} alt="google" className={'sidebar-logo-image'}/>
            </a>

        </Sidebar>
        <Button className={'mobile-sidebar-toggle-button'} icon="pi pi-align-justify" onClick={() => setVisible(true)}/>

    </div>
);
}

export default MobileSidebar;