import React from 'react';
import logo from "../../assets/images/logo.png";
import navItems from '@modules/home/navigations/items';
import campaignNavItems from '@modules/campaign/navigations/items';
import {ExpandMoreOutlined} from "@material-ui/icons";
import {NavLink} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChevronRightSvgIcon from "@components/icons/chevron_right";


const Sidebar = () => {



    const navItemsAll = [
        ...navItems,
        ...campaignNavItems,

    ];

    return (
        <div className="d-flex flex-column flex-shrink-0  sidebar d-sm-none d-lg-flex d-xs-none d-none">
                        <a href="/" className="align-items-center mb-3 me-md-auto link-body-emphasis text-decoration-none sidebar-logo-image-a pt-2 pl-5">
                            <img src={logo} alt="google" className={'sidebar-logo-image'}/>
                        </a>
            <ul className="nav nav-pills flex-column mb-auto">
                {navItemsAll.map((item) => (
                    <li className="nav-item" key={item.id}>
                        {item.subItems ? (
                            // Render menu item with sub-items
                            <>
                                <div
                                    className="nav-link link-body-emphasis ml-3 mr-3"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#${item.title.replace(/\s/g, '')}SubMenu`}
                                >
                                    {item.icon}

                                    {/*{item.link ?*/}
                                    {/*    <NavLink to={item.link} className="nav-link sidebar-nav d-inline-block" style={{paddingLeft:0}}>*/}
                                    {/*        <span className="sidebar-title">{item.title}</span>*/}
                                    {/*    </NavLink> :*/}
                                        <span className="sidebar-title">{item.title}</span>
                                     {/*}*/}


                                    <ChevronRightSvgIcon className="expanded-arrow" />
                                </div>
                                <div id={`${item.title.replace(/\s/g, '')}SubMenu`} className="collapse sidebar-sub-menu">
                                    <ul className="nav flex-column ps-3 sub-menu">
                                        {item.subItems.map((subItem) => (
                                            <li className="nav-item" key={subItem.id}>
                                                <NavLink to={subItem.link} className="nav-link sub-item">
                                                    {subItem.title}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        ) : (
                            // Render regular menu item
                            <NavLink to={item.link} className="nav-link sidebar-nav  ml-3">
                                {item.icon}
                                <span className="sidebar-title">{item.title}</span>
                            </NavLink>
                        )}
                    </li>
                ))}
            </ul>

        </div>
    );
};



export default Sidebar;