import React from "react";
import RectangleSvgIcon from "@components/icons/rectangel";


const campaignNavItems = [
    {
        id: 'campaign',
        title: 'Campaign',
        icon: <RectangleSvgIcon className="sidebar-icon mr-2" />,
        link: '/campaign',
        subItems: [
            {
                id: 'campaign_google_ads',
                title: 'Ads',
                link: '/ads'
            },
            // {
            //     id: 'campaign_meta_ads',
            //     title: 'Meta Ads',
            //     link: '/campaign/meta-ads'
            // },
            {
                id: 'campaign_email_marketing',
                title: 'E-mail & Push',
                link: '/email-marketing'
            },
            {
                id: 'external_campaign',
                title: 'Custom',
                link: '/custom-campaigns'
            },
            {
                id: 'external_campaign_channel',
                title: 'Custom Types',
                link: '/empty-types'
            },
            // {
            //     id: 'campaign_push_notification',
            //     title: 'Push-Notification',
            //     link: '/campaign/push-notification'
            // },
        ]
    },
]

export default campaignNavItems
