import React from 'react';

const ChevronRightSvgIcon = ({ color ,className}) => (
    <svg width="16" height="16" viewBox="0 0 16 16" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.46875 12.7969L10.4688 8.79688C10.6042 8.67188 10.6719 8.51823 10.6719 8.33594C10.6719 8.15365 10.6042 7.99479 10.4688 7.85938L6.46875 3.85938C6.34375 3.73438 6.1875 3.67188 6 3.67188C5.8125 3.67188 5.65625 3.73438 5.53125 3.85938C5.39583 3.99479 5.32812 4.15365 5.32812 4.33594C5.32812 4.51823 5.39583 4.67188 5.53125 4.79688L9.0625 8.32812L5.53125 11.8594C5.39583 11.9948 5.32812 12.1536 5.32812 12.3359C5.32812 12.5182 5.39583 12.6719 5.53125 12.7969C5.65625 12.9323 5.8125 13 6 13C6.1875 13 6.34375 12.9323 6.46875 12.7969Z" fill="#1A1C1E"/>
    </svg>


);

export default ChevronRightSvgIcon;