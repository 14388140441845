import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/js/dist/dropdown';
import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {  Button } from "@mui/material";
import RectangleDotsSvgIcon from "@components/icons/rectangel_dots";
import Message from "@components/icons/message";
import Ring from "@components/icons/ring";
import Avatar from "@components/icons/avatar";
import Flag from "@components/icons/flag";


const Navbar = ({formData, setFormData}) => {
    return (
        <nav className="navbar navbar-expand navbar-dark bg-white header-nav p-4">
            <div>
                <Button className={'navbar-icon-button'} onClick={null}>
                    <FontAwesomeIcon icon={faBars} className={'navbar-icon'}></FontAwesomeIcon>
                </Button>
            </div>
            <div className={'d-flex right-navbar-div'}>
                <div className={'right-navbar-icon-div'}>
                    <RectangleDotsSvgIcon />
                </div>
                <div className={'right-navbar-icon-div'}>
                    <Message />
                </div>
                <div className={'right-navbar-icon-div'}>
                    <Ring />
                </div>
                <div>
                    <Avatar />
                </div>
                <div className={'right-navbar-flag-icon-div'}>
                    <Flag />
                </div>



            </div>


        </nav>
    );
}

export default Navbar;