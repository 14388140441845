import React from 'react';



const AuthRoutes = [
    {
        navLink: '/home',
        component: React.lazy(() => import('../pages/home')),
        authRequired: false,
        layout:'vertical'
    },
];

export default AuthRoutes;